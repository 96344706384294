<template>
  <div class="transport-add main-block">
    <form action="" @submit.prevent="save()">
      <div class="transport-add__box main-block__head">
        <h1 class="transport-add__box-title main-block__title">Информация</h1>
        <button class="main-block__btn default-btn" type="submit">Сохранить</button>
      </div>
      <ul class="transport-add__items">
        <li class="transport-add__item flex">
          <p class="transport-add__key">Город *</p>
          <b-form-select
            v-model="cityId"
            class="transport-add__value main__select"
            :options="listCities"
            text-field="name"
            value-field="id"
            @change="onChange('cityId', cityId)"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">Марка *</p>
          <b-form-select
            v-model="mark"
            class="transport-add__value main__select"
            :options="list"
            text-field="name"
            value-field="id"
            @change="transportMark()"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li v-show="mark" class="transport-add__item flex">
          <p class="transport-add__key">Модель *</p>
          <b-form-select
            v-model="model"
            class="transport-add__value main__select"
            :options="listModel"
            text-field="name"
            value-field="id"
            @change="onChange('model', model)"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">Номер машины *</p>
          <input
            v-model="number"
            type="text"
            class="transport-add__value"
            placeholder="000 ABC 00"
            @input="onChange('number', number)"
          />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">VIN код *</p>
          <input
            v-model="registration"
            type="text"
            class="transport-add__value"
            placeholder="VIN"
            @input="onChange('registration', registration)"
          />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">Вид *</p>
          <b-form-select
            v-model="body"
            class="transport-add__value main__select"
            :options="computedBodyList"
            @change="onChange('body', body)"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">Год выпуска *</p>
          <b-form-select
            v-model="carDate"
            class="transport-add__value"
            :options="options"
            value-field="value"
            @change="onChange('carDate', carDate)"
          />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">Температурный режим *</p>
          <input
            v-model="temp"
            type="number"
            class="transport-add__value transport-add__value_aligin"
            placeholder="°С"
            @input="onChange('temp', temp)"
          />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">Датчик ТС</p>
          <b-form-select
            v-model="label"
            class="transport-add__value main__select"
            :options="navixyTransportLabels"
            @change="onChange('label', label)"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">GPS навигатор</p>
          <b-form-select
            v-model="navigation"
            class="transport-add__value main__select"
            :options="navigationList"
            @change="onChange('navigation', navigation)"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li v-if="this.transportData.body === 'fura'" class="transport-add__item flex">
          <p class="transport-add__key">Прицеп</p>
          <b-form-select
            v-model="trailer_id"
            class="transport-add__value main__select"
            
            @change="onChange('trailer_id', trailer_id)"
          >
          <option value="null">Выберите</option>
          <option
            v-for="(option, idx) in listTrailers"
            :key="idx"
            :value="option.id"
            :title="option.mark_model || null"
          >
            {{ option.mark_model + ' | ' + option.number }}
          </option>
          </b-form-select>

          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="transport-add__item flex">
          <p class="transport-add__key">Статус</p>
          <b-form-select
            v-model="status"
            class="transport-add__value main__select"
            :options="statusList"
            @change="onChange('status', status)"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li v-if="status === 'on_rent'" class="transport-add__item flex">
          <p class="transport-add__key">Срок аренды</p>
          <button class="transport-add__value filter__item-btn popup__inputs" @click.prevent="dateShow = !dateShow" >
            <b-icon
              class="filter__item-btn-icon icon-right"
              style="color: #707070; margin-left: 10px"
              icon="calendar4-week"
            />
          </button>
          <div v-show="dateShow" class="sub-header__calendar">
            <vc-date-picker
              v-model="range"
              class="add__calendar"
              color="green"
              :rows="1"
              is-range
              @input="inputData()"
            />
            <button class="btn btn-primary mt-2" @click.prevent="hideModal()">Ok</button>
            <button class="btn btn-primary mt-2" @click.prevent="inputDataClean('')">Очистить</button>
          </div>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import transportBodyMixin from "../../../mixins/transportBody";

export default {
  mixins: [transportBodyMixin],
  props: {
    transportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      label: null,
      mark: null,
      model: null,
      number: null,
      body: null,
      carDate: null,
      temp: null,
      dimensions: null,
      cityId: null,
      tracker_id: null,
      options2: [
        { value: null, text: "Выберите" },
        { value: "station ", text: "Универсал" },
        { value: "hatchback", text: "Хэтчбек" },
        { value: "minivan", text: "Минивен" },
      ],
      options3: [
        { value: null, text: "Выберите" },
        { value: 1, text: "Выберите1" },
        { value: 2, text: "Выберите2" },
      ],
      navixyTransportLabels: [],
      trailer_id: null,
      registration: null,
      navigation: null,
      navigationList: [
        { value: null, text: "Выберите" },
        { value: 'Navixy', text: "Navixy" },
        { value: 'Wialon', text: "Wialon" },
      ],
      statusList: [
        { value: null, text: "Выберите" },
        { value: 'free', text: "Свободен" },
        { value: 'busy', text: "Занят" },
        { value: 'repair', text: "Ремонт", disabled: true },
        { value: 'sold', text: "Продана" },
        { value: 'on_rent', text: "В аренде" },
      ],
      status: null,
      dateShow: false,
      range: [],
    };
  },
  computed: {
    ...mapState([
      "marksProducts",
      "modelProducts",
      "addTransport",
      "citiesProducts",
      "bodyList",
      "navixyTransport",
      "transportList",
    ]),
    computedBodyList() {
      const arr = [];
      if (this.bodyList) {
        this.bodyList.forEach((elem) => {
          arr.push({
            value: elem,
            text: this.translateBody(elem),
          });
        });
        return arr;
      } else return [];
    },
    list() {
      if (this.marksProducts) {
        return [{ id: null, name: "Выберите", disabled: true }, ...this.marksProducts];
      }
      return [{ id: null, name: "Выберите", disabled: true }];
    },
    listCities() {
      if (this.citiesProducts) {
        return [{ id: null, name: "Выберите", disabled: true }, ...this.citiesProducts];
      }
      return [{ id: null, name: "Выберите", disabled: true }];
    },
    listModel() {
      if (this.modelProducts) {
        return [{ id: null, name: "Выберите", disabled: true }, ...this.modelProducts];
      }
      return [{ id: null, name: "Выберите", disabled: true }];
    },
    options() {
      const res = [{ value: null, text: "Выберите" }];
      for (let i = 1980; i <= new Date().getFullYear(); i++) {
        res.push({
          value: `${i}-00-00`,
          text: i,
        });
      }
      return res.reverse();
    },
    listTrailers() {
      if (this.transportList.length) {
        if (this.transportData && this.transportData.trailer)
          return [{ id: this.transportData.trailer.id, mark_model: this.transportData.trailer.mark_model, number: this.transportData.trailer.number, disabled: false }, ...this.transportList];
        return [ ...this.transportList];
      }
      return [];
    },
  },
  mounted() {
    if (this.transportData && this.$route.query.id) {
      this.mark = this.transportData?.mark;
      if (this.mark) {
        this.$store.dispatch("getModelProducts", this.mark);
      }
      this.model = this.transportData?.model;
      this.number = this.transportData?.number;
      this.body = this.transportData?.body;
      this.carDate = this.transportData?.carDate;
      this.temp = this.transportData?.temp;
      this.dimensions = this.transportData?.dimensions;
      this.label = this.transportData?.label;
      this.cityId = this.transportData?.cityId;
      this.tracker_id = this.transportData?.tracker_id;
      this.trailer_id = this.transportData?.trailer_id;
      this.registration = this.transportData?.registration;
      this.navigation = this.transportData?.navigation;
      this.status = this.transportData?.status;
      this.rent_from_date = this.transportData?.rent_from_date;
      this.rent_to_date = this.transportData?.rent_to_date;
    }
  },
  async created() {
    this.$store.dispatch("getMarksProducts");
    this.$store.dispatch("getCitiesProducts");
    this.$store.dispatch("getBodyList");
    this.$store.dispatch("getNavixyTransport").then(() => {
      if (this.navixyTransport) {
        this.navixyTransportLabels.push({
          value: null,
          text: "Выберите",
          disabled: true,
        });
        this.navixyTransport.forEach((element) => {
          this.navixyTransportLabels.push(element.label);
        });
        this.navixyTransportLabels.push({
          value: null,
          text: "Нет в списке",
        });
      }
    });
    this.$store.dispatch("getTransportList", {type: 'get_not_occupied_trailers'});
  },
  methods: {
    transportMark() {
      this.onChange("mark", this.mark);
      this.$store.dispatch("getModelProducts", this.mark);
    },
    save() {
      this.$emit("save");
    },
    onChange(key, params) {
      this.$emit("onInputHandler", {
        key,
        params,
      });
    },
    hideModal() {
      this.dateShow = false;
    },
    inputData() {
      if (this.range && this.range.start && this.range.end) {

        this.range.start = new Date(this.range.start).toISOString().split("T")[0];
        this.range.end = new Date(this.range.end).toISOString().split("T")[0];
        this.rent_to_date = this.range.end;
        this.rent_from_date = this.range.start;
        this.onChange('rent_to_date', this.rent_to_date);
        this.onChange('rent_from_date', this.rent_from_date);
      }
    },
    inputDataClean() {
      this.range = [];
      this.hideModal();
    },
  },
};
</script>

<style scoped>
.transport-add__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}

.transport-add__item {
  padding-top: 11.5px;
  height: 27.5px;
  position: relative;
}

.transport-add__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.main__select-icon {
  right: 8px;
  top: 53%;
  width: 7px;
}

.transport-add__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.transport-add__value {
  width: 90px;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-right: 10px;
}

.transport-add__value_aligin {
  text-align: end;
}
.popup__inputs {
  margin-top: 10px;
  color: #ACACAC;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.add__calendar {
  position: static;
  background: #fff;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  bottom: 0;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.filter__item-btn {
  height: 100%;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  color: #707070;
  text-align: start;
  position: relative;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}
</style>
